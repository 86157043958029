import { Box, Heading } from '@chakra-ui/react';
import QRCode from 'qrcode';
import React, { useState } from 'react';

import { VaccineTypes } from '../../../config';
import { AnswerState, VaccineTexts } from '../../interfaces';
import ConsentForm from './ConsentForm';
import ConsentFormResult from './ConsentFormResult';

interface Props {
  vaccinetexts: VaccineTexts;
}

export default function FormPage({ vaccinetexts }: Props): JSX.Element {
  const initialAnswers = {
    birthCountry: 'HUN',
    country: 'HUN',
    vaccineType: 'X',
  };
  const [answers, setAnswers] = useState<AnswerState>(initialAnswers);
  const [finished, setFinished] = useState(false);

  const resetForm = (): void => {
    setAnswers(initialAnswers);
  };

  return (
    <Box width={['90%', null, '80%', '60%']}>
      {!finished && (
        <>
          <Heading textAlign="center" mb={2}>
            Hozzájárulási nyilatkozat
          </Heading>
          <ConsentForm
            answers={answers}
            setAnswers={setAnswers}
            resetForm={resetForm}
            setFinished={setFinished}
          />
        </>
      )}
      {finished && (
        <ConsentFormResult
          answers={answers}
          vaccinetext={
            answers.vaccineType !== 'X'
              ? vaccinetexts[VaccineTypes[answers.vaccineType].id]
              : null
          }
          setFinished={setFinished}
        />
      )}
    </Box>
  );
}
