import { Box, BoxProps, Button, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';

import { VaccineTypes } from '../../../config';
import consentFormData from '../../data/consentform.json';
import countryCodeData from '../../data/countrycodes.json';
import {
  AdditionalQuestion,
  AnswerState,
  ErrorState,
  PersonalDataQuestion,
} from '../../interfaces';
import { FormCheckPersonalItem, FormCheckVaccineItem } from './FormCheckItem';
import FormItem from './FormItem';
import FormSlider from './FormSlider';
import QuestionItem from './QuestionItem';
import VaccineItem from './VaccineItem';

interface Props {
  answers: AnswerState;
  setAnswers: (answerState: AnswerState) => void;
  resetForm: () => void;
  setFinished: (finished: boolean) => void;
}

export default function ConsentForm({
  answers,
  setAnswers,
  resetForm,
  setFinished,
}: Props): JSX.Element {
  const [tabIndex, setTabIndex] = useState(0);
  const [personalErrors, setPersonalErrors] = useState({} as ErrorState);
  const [additionalErrors, setAdditionalErrors] = useState({} as ErrorState);

  const handleSubmit = (): void => {
    setFinished(true);
  };

  const getAnswer = (id: string): string => {
    return answers[id];
  };
  const setAnswer = (id: string, text: string): void => {
    setAnswers({ ...answers, [id]: text });
  };

  const validateTajCdv = (taj: string): boolean => {
    let isValid = false;
    const tajNums = taj.split('').map((x) => parseInt(x, 10));
    const cdv =
      3 * (tajNums[0] + tajNums[2] + tajNums[4] + tajNums[6]) +
      7 * (tajNums[1] + tajNums[3] + tajNums[5] + tajNums[7]);
    if (cdv % 10 === tajNums[8]) {
      isValid = true;
    }
    return isValid;
  };

  const validatePersonalData = (): boolean => {
    const newErrors = {} as ErrorState;
    let isError = false;
    consentFormData.personalData.forEach((question: PersonalDataQuestion) => {
      if (!answers[question.id]) {
        isError = true;
        newErrors[question.id] = [
          ...(newErrors[question.id] || []),
          'Kötelező mező',
        ];
        return;
      }
      if (question.type === 'text') {
        if (answers[question.id].length < 1) {
          isError = true;
          newErrors[question.id] = [
            ...(newErrors[question.id] || []),
            'Kötelező mező',
          ];
        } else if (
          question.maxLength &&
          answers[question.id].length > question.maxLength
        ) {
          isError = true;
          newErrors[question.id] = [
            ...(newErrors[question.id] || []),
            `Túl hosszú érték (max ${question.maxLength})`,
          ];
        }
        if (question?.validation === 'taj') {
          if (
            !/^\d*$/.test(answers[question.id]) ||
            answers[question.id].length !== 9
          ) {
            isError = true;
            newErrors[question.id] = [
              ...(newErrors[question.id] || []),
              'Hibás formátum (9db szám)',
            ];
          } else if (!validateTajCdv(answers[question.id])) {
            isError = true;
            newErrors[question.id] = [
              ...(newErrors[question.id] || []),
              'Érvénytelen TAJ szám',
            ];
          }
        } else if (
          question?.validation === 'zip' &&
          !/^\d*$/.test(answers[question.id])
        ) {
          isError = true;
          newErrors[question.id] = [
            ...(newErrors[question.id] || []),
            'Hibás formátum (csak számok)',
          ];
        } else if (
          (question?.validation === 'phone' &&
            !/^[+]?\d{10,}$/.test(answers[question.id])) ||
          (question?.validation === 'email' &&
            !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{2,})+$/.test(
              answers[question.id],
            ))
        ) {
          isError = true;
          newErrors[question.id] = [
            ...(newErrors[question.id] || []),
            'Hibás formátum',
          ];
        }
      }
    });
    setPersonalErrors(newErrors);
    return isError;
  };

  const validateAdditionalData = (): boolean => {
    const newErrors = {} as ErrorState;
    let isError = false;
    consentFormData.questions.forEach((question: AdditionalQuestion) => {
      if (!answers[question.id]) {
        isError = true;
        newErrors[question.id] = [
          ...(newErrors[question.id] || []),
          'Kötelező mező',
        ];
        return;
      }
      if (question.additional && answers[question.id] === '1') {
        if (!answers[question.additional.id]) {
          isError = true;
          newErrors[question.additional.id] = [
            ...(newErrors[question.additional.id] || []),
            'Kötelező mező',
          ];
          return;
        }
        if (answers[question.additional.id].length < 1) {
          isError = true;
          newErrors[question.additional.id] = [
            ...(newErrors[question.additional.id] || []),
            'Kötelező mező',
          ];
        } else if (
          question.additional.maxLength &&
          answers[question.additional.id].length > question.additional.maxLength
        ) {
          isError = true;
          newErrors[question.additional.id] = [
            ...(newErrors[question.additional.id] || []),
            `Túl hosszú érték (max ${question.additional.maxLength})`,
          ];
        }
      }
    });
    setAdditionalErrors(newErrors);
    return isError;
  };

  const renderPersonalQuestion = (
    id: string,
    props?: BoxProps,
  ): JSX.Element => {
    return (
      <FormItem
        personalQuestion={
          consentFormData.personalData.filter((x) => x.id === id)[0]
        }
        getAnswer={getAnswer}
        setAnswer={setAnswer}
        errors={personalErrors}
        {...props}
      />
    );
  };

  return (
    <Box>
      <FormSlider stage={tabIndex + 1} my="4rem" />
      {tabIndex === 0 && (
        <Box>
          <Box fontStyle="italic" mb={8}>
            Minden mező kitöltése kötelező!
          </Box>
          <Box>
            <Box fontWeight="bold">Név</Box>
            <Flex flexDirection={['column', null, 'row']} mt="1rem">
              {renderPersonalQuestion('lastName', {
                width: ['100%', null, '47%'],
                mr: [0, null, '1rem'],
                mb: ['1rem', null, 0],
                flexShrink: 0,
              })}
              {renderPersonalQuestion('firstName', {
                width: ['100%', null, '47%'],
                flexGrow: 1,
              })}
            </Flex>
          </Box>
          <Box mt="2rem">
            <Box fontWeight="bold">Születési adatok</Box>
            <Flex flexWrap="wrap" mt="1rem">
              {renderPersonalQuestion('sex', {
                width: '10rem',
                mr: '1rem',
                mb: ['1rem', null, 0],
              })}
              {renderPersonalQuestion('birthDate', { width: '20rem' })}
            </Flex>
          </Box>
          <Box mt="1rem">
            <Flex flexDirection={['column', null, 'row']} mb="1rem">
              {renderPersonalQuestion('birthCountry', {
                width: ['100%', null, '15rem'],
                mr: [0, null, '1rem'],
                mb: ['1rem', null, 0],
                flexShrink: 0,
              })}
              {renderPersonalQuestion('birthTown', {
                flexGrow: 1,
              })}
            </Flex>
            <Flex flexDirection={['column', null, 'row']}>
              {renderPersonalQuestion('motherName', {
                flexGrow: 1,
                mr: [0, null, '1rem'],
                mb: ['1rem', null, 0],
              })}
              {renderPersonalQuestion('taj', {
                width: ['100%', null, '30%'],
                flexShrink: 0,
              })}
            </Flex>
          </Box>
          <Box mt="2rem">
            <Box fontWeight="bold">Lakcím</Box>
            <Flex flexDirection={['column', null, 'row']} mt="1rem">
              {renderPersonalQuestion('country', {
                width: ['100%', null, '15rem'],
                mr: [0, null, '1rem'],
                mb: ['1rem', null, 0],
              })}
              {renderPersonalQuestion('zipcode', {
                width: ['100%', null, '15rem'],
              })}
            </Flex>
            {renderPersonalQuestion('town', {
              width: '100%',
              mt: '1rem',
            })}
            {renderPersonalQuestion('address', {
              width: '100%',
              mt: '1rem',
            })}
          </Box>
          <Box mt="2rem">
            <Box fontWeight="bold">Elérhetőség</Box>
            <Flex flexDirection={['column', null, 'row']} mt="1rem">
              {renderPersonalQuestion('phone', {
                width: ['100%', null, '15rem'],
                mr: [0, null, '1rem'],
                mb: ['1rem', null, 0],
                flexShrink: 0,
              })}
              {renderPersonalQuestion('email', {
                flexGrow: 1,
              })}
            </Flex>
          </Box>
          <Flex justify="flex-end" mt="2rem">
            <Button
              width={['45%', null, '10rem']}
              colorScheme="dpcblue"
              onClick={(): void => {
                const isError = validatePersonalData();
                if (!isError) {
                  setTabIndex(tabIndex + 1);
                  if (typeof window !== 'undefined') window.scrollTo(0, 0);
                }
              }}
            >
              Tovább
            </Button>
          </Flex>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box>
          <Box fontStyle="italic" mb={8}>
            Minden mező kitöltése kötelező!
          </Box>
          <VaccineItem
            vaccineQuestion={consentFormData.vacType}
            getAnswer={getAnswer}
            setAnswer={setAnswer}
          />
          {consentFormData.questions.map(
            (item: AdditionalQuestion, index: number) => {
              return (
                <QuestionItem
                  key={item.id}
                  isOdd={index % 2 === 1}
                  question={item}
                  getAnswer={getAnswer}
                  setAnswer={setAnswer}
                  errors={additionalErrors}
                />
              );
            },
          )}
          <Flex justify="space-between" mt="2rem">
            <Button
              width={['45%', null, '10rem']}
              backgroundColor="dpcgray.300"
              onClick={(): void => {
                setTabIndex(tabIndex - 1);
              }}
            >
              Vissza
            </Button>
            <Button
              width={['45%', null, '10rem']}
              colorScheme="dpcblue"
              onClick={(): void => {
                const isError = validateAdditionalData();
                if (!isError) {
                  setTabIndex(tabIndex + 1);
                  if (typeof window !== 'undefined') window.scrollTo(0, 0);
                }
              }}
            >
              Tovább
            </Button>
          </Flex>
        </Box>
      )}
      {tabIndex === 2 && (
        <Box>
          <Box>
            <FormCheckPersonalItem
              title="Név:"
              value={`${getAnswer('lastName')} ${getAnswer('firstName')}`}
            />
            <FormCheckPersonalItem
              title="Születési hely, idő:"
              value={`${
                countryCodeData[getAnswer('birthCountry')]
              }, ${getAnswer('birthTown')}, ${new Date(
                getAnswer('birthDate'),
              ).toLocaleDateString('hu-HU', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}`}
            />
            <FormCheckPersonalItem
              title="Anyja neve:"
              value={getAnswer('motherName')}
            />
            <FormCheckPersonalItem title="TAJ szám:" value={getAnswer('taj')} />
            <FormCheckPersonalItem
              title="Lakcím:"
              value={`${countryCodeData[getAnswer('country')]}, ${getAnswer(
                'zipcode',
              )} ${getAnswer('town')}, ${getAnswer('address')}`}
            />
            <FormCheckPersonalItem
              title="Telefonszám:"
              value={getAnswer('phone')}
            />
            <FormCheckPersonalItem
              title="E-mail cím:"
              value={getAnswer('email')}
            />
            <FormCheckPersonalItem
              title="Vakcina:"
              value={VaccineTypes[getAnswer('vaccineType')].id}
            />
            {consentFormData.questions.map(
              (item: AdditionalQuestion, index: number) => {
                return (
                  <FormCheckVaccineItem
                    key={item.id}
                    isOdd={index % 2 === 1}
                    question={item}
                    getAnswer={getAnswer}
                  />
                );
              },
            )}
          </Box>
          <Flex justify="space-between" mt="2rem">
            <Button
              width={['45%', null, '10rem']}
              backgroundColor="dpcgray.300"
              onClick={(): void => {
                setTabIndex(tabIndex - 1);
              }}
            >
              Vissza
            </Button>
            <Button
              width={['45%', null, '10rem']}
              colorScheme="dpcblue"
              onClick={(): void => {
                const isError = validateAdditionalData();
                if (!isError) handleSubmit();
              }}
            >
              Generálás
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
}
