import 'react-datepicker/dist/react-datepicker.css';

import {
  Box,
  BoxProps,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import countryCodeData from '../../data/countrycodes.json';
import {
  CountryCodesDataType,
  ErrorState,
  PersonalDataQuestion,
} from '../../interfaces';

interface Props extends BoxProps {
  personalQuestion: PersonalDataQuestion;
  getAnswer: (id: string) => string;
  setAnswer: (id: string, text: string) => void;
  errors: ErrorState;
}

export default function FormItem({
  personalQuestion,
  getAnswer,
  setAnswer,
  errors,
  ...props
}: Props): JSX.Element {
  const renderQuestion = (): JSX.Element => {
    if (personalQuestion.type === 'text') {
      return (
        <Input
          placeholder={personalQuestion.placeholder}
          value={getAnswer(personalQuestion.id) || ''}
          maxLength={personalQuestion.maxLength}
          type={
            personalQuestion?.validation === 'taj' ||
            personalQuestion?.validation === 'zip'
              ? 'number'
              : 'text'
          }
          onChange={(e: React.FormEvent): void => {
            const newValue = (e.target as HTMLInputElement).value;
            if (setAnswer) {
              if (
                (personalQuestion?.validation === 'taj' ||
                  personalQuestion?.validation === 'zip') &&
                personalQuestion.maxLength &&
                newValue.length > personalQuestion.maxLength
              )
                return;
              setAnswer(personalQuestion.id, newValue);
            }
          }}
        />
      );
    }
    if (personalQuestion.type === 'dropdown') {
      return (
        <Select
          value={
            getAnswer(personalQuestion.id) ??
            (personalQuestion.options === 'sex' ? '' : 'HUN')
          }
          onChange={(e: React.FormEvent): void => {
            if (setAnswer)
              setAnswer(
                personalQuestion.id,
                (e.target as HTMLInputElement).value,
              );
          }}
        >
          {personalQuestion.options === 'sex' && (
            <>
              <option value="" disabled>
                Neme
              </option>
              <option value="N">Nő</option>
              <option value="F">Férfi</option>
            </>
          )}
          {personalQuestion.options === 'country-list' && (
            <>
              {Object.entries(countryCodeData as CountryCodesDataType).map(
                ([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ),
              )}
            </>
          )}
        </Select>
      );
    }
    if (personalQuestion.type === 'date') {
      return (
        <Box>
          <Input
            placeholder={personalQuestion.placeholder}
            value={getAnswer(personalQuestion.id) || ''}
            type="date"
            onChange={(e: React.FormEvent): void => {
              setAnswer(
                personalQuestion.id,
                (e.target as HTMLInputElement).value,
              );
            }}
          />
          <Box fontSize="0.75rem" fontStyle="italic" pl={4}>
            {personalQuestion.label}
          </Box>
        </Box>
      );
    }
    return <Box />;
  };

  return (
    <FormControl id={personalQuestion.id} {...props}>
      {renderQuestion()}
      {errors[personalQuestion.id]?.length > 0 && (
        <Box color="red.500">
          {errors[personalQuestion.id].map((e) => (
            <Text key={e}>{e}</Text>
          ))}
        </Box>
      )}
    </FormControl>
  );
}
