import { Box, Button, Flex, Heading, Image, useToast } from '@chakra-ui/react';
import { BlobProvider } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';

import { EmailData } from '../../../config';
import { AnswerState, VaccineText } from '../../interfaces';
// eslint-disable-next-line import/extensions
import Email from '../../smtp.js';
import blobToBase64 from '../../util/blobtobase64';
import { encodeAnswers } from '../../util/encode-answers';
import generateBody from '../../util/generate_emailbody';
import FilledDocument from './Document';

interface Props {
  answers: AnswerState;
  vaccinetext: VaccineText | null;
  setFinished: (finished: boolean) => void;
}

export default function ConsentFormResult({
  answers,
  vaccinetext,
  setFinished,
}: Props): JSX.Element {
  const [qrcodeDataURI, setQrcodeDataURI] = useState('');

  const toast = useToast();

  useEffect(() => {
    QRCode.toDataURL(
      encodeAnswers(answers),
      { errorCorrectionLevel: 'L' },
      (err, url) => {
        if (!err) {
          setQrcodeDataURI(url);
        } else {
          toast({
            title: 'Hiba!',
            description: 'Hiba történt a generáláskor',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      },
    );
  }, [answers, toast]);

  const backToConsent = (e: React.FormEvent): void => {
    e.preventDefault();
    setFinished(false);
  };

  const sendMail = (blob: Blob, formAnswers: AnswerState): void => {
    /* blobToBase64(blob)
      .then((res) => {
        const bodyText = generateBody(
          `${formAnswers.lastName} ${formAnswers.firstName}`,
        );
        return Email.send({
          SecureToken: EmailData.token,
          To: formAnswers.email,
          From: EmailData.sender,
          Subject: EmailData.subject,
          Body: bodyText,
          Attachments: [
            {
              name: `${formAnswers.lastName} ${formAnswers.firstName}_HNY.pdf`,
              data: res,
            },
          ],
        });
      })
      .then((message) => {
        if (message === 'OK') {
          toast({
            title: 'Email elküldve',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Hiba!',
            description: 'Hiba történt az email küldés közben!',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          console.error(`Error during send: ${message}`);
        }
      }); */
  };

  return (
    <Flex width="full" align="center" justifyContent="center" flexDir="column">
      <Box p={2} textAlign="center">
        <Heading>Hozzájárulási nyilatkozatod QR kódja</Heading>
      </Box>
      <Box my={4} px="auto">
        {qrcodeDataURI && <Image boxSize="200px" src={qrcodeDataURI} />}
      </Box>
      <Box m={2}>
        {qrcodeDataURI && (
          <BlobProvider
            document={
              <FilledDocument
                answers={answers}
                qrcodeDataURI={qrcodeDataURI}
                vaccinetext={vaccinetext}
              />
            }
          >
            {({ blob, url, loading, error }): React.ReactNode =>
              loading ? (
                'PDF generálása folyamatban...'
              ) : (
                <Flex flexDirection="column">
                  <Button
                    colorScheme="dpcblue"
                    mb={4}
                    as="a"
                    href={url}
                    download={`${answers.lastName} ${answers.firstName}_HNY.pdf`}
                  >
                    Fájl letöltése
                  </Button>
                  <Button
                    display="none"
                    colorScheme="dpcblue"
                    onClick={(): void => {
                      if (blob) {
                        sendMail(blob, answers);
                      } else {
                        toast({
                          title: 'Hiba!',
                          description: 'Hiba történt az email küldés közben!',
                          status: 'error',
                          duration: 3000,
                          isClosable: true,
                        });
                        console.error('No blob found!');
                      }
                    }}
                  >
                    Email küldése
                  </Button>
                </Flex>
              )
            }
          </BlobProvider>
        )}
      </Box>
      <Box>
        <Button onClick={backToConsent} colorScheme="dpcblue" variant="link">
          Visszalépés a Hozzájárulási nyilatkozathoz
        </Button>
      </Box>
    </Flex>
  );
}
