/* eslint-disable no-nested-ternary */
import { CheckIcon } from '@chakra-ui/icons';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import React from 'react';

interface SliderProps extends BoxProps {
  stage: number;
}

export default function FormSlider({
  stage,
  ...props
}: SliderProps): JSX.Element {
  return (
    <Flex
      width="100%"
      justifyContent="space-between"
      position="relative"
      {...props}
    >
      <FormSliderIcon stage={1} currentStage={stage} />
      <FormSliderIcon stage={2} currentStage={stage} />
      <FormSliderIcon stage={3} currentStage={stage} />
      <FormSliderLine type={1} />
      <FormSliderLine type={2} />
    </Flex>
  );
}

const widthParams = {
  sm: '33%',
  lg: '12rem',
};
const paddingParam = {
  sm: '2rem',
  lg: '4rem',
};

interface SliderLineProps {
  type: number;
}

function FormSliderLine({ type }: SliderLineProps): JSX.Element {
  const style =
    type === 1
      ? {
          right: [
            `calc(50% + ${paddingParam.sm})`,
            null,
            `calc(50% + ${paddingParam.lg})`,
          ],
        }
      : {
          left: [
            `calc(50% + ${paddingParam.sm})`,
            null,
            `calc(50% + ${paddingParam.lg})`,
          ],
        };
  return (
    <Box
      position="absolute"
      top="0"
      height={['1.5rem', null, '3rem']}
      transform="translateY(-50%)"
      width={[
        `calc(50% - ${widthParams.sm} / 2 - 2 * ${paddingParam.sm})`,
        null,
        `calc(50% - ${widthParams.lg} / 2 - 2 * ${paddingParam.lg})`,
      ]}
      borderBottomWidth="1px"
      borderColor="dpcgray.400"
      {...style}
    />
  );
}
interface SliderIconProps {
  stage: number;
  currentStage: number;
}
function FormSliderIcon({ stage, currentStage }: SliderIconProps): JSX.Element {
  const stageState = stage - currentStage;
  const texts = {
    '1': 'Személyes adatok',
    '2': 'Egészségügyi kérdések',
    '3': 'Adatlap ellenőrzése',
  };
  const styleCircle =
    stageState < 0
      ? {
          backgroundColor: 'dpcgreen.500',
          color: 'white',
        }
      : stageState === 0
      ? {
          backgroundColor: 'dpcblue.500',
          color: 'white',
        }
      : {
          borderWidth: '2px',
          borderColor: 'dpcgray.400',
          color: 'dpcgray.400',
        };

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      width={[widthParams.sm, null, widthParams.lg]}
      mx="0.5rem"
    >
      <Flex
        fontSize={['1rem', null, '2rem']}
        justifyContent="center"
        alignItems="center"
        width={['1.5rem', null, '3rem']}
        height={['1.5rem', null, '3rem']}
        borderRadius="50%"
        {...styleCircle}
      >
        {stageState < 0 ? <CheckIcon /> : <Box fontWeight="bold">{stage}</Box>}
      </Flex>
      <Box
        textAlign="center"
        fontSize={['0.75rem', '1rem']}
        color={stageState === 0 ? 'inherit' : 'dpcgray.400'}
      >
        {texts[stage]}
      </Box>
    </Flex>
  );
}
