import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import React from 'react';

import { AdditionalQuestion } from '../../interfaces';

interface PersonalProps {
  title: string;
  value: string;
}

export function FormCheckPersonalItem({
  title,
  value,
}: PersonalProps): JSX.Element {
  return (
    <Flex>
      <Box fontWeight="bold" mr={1}>
        {title}
      </Box>
      <Box>{value}</Box>
    </Flex>
  );
}

interface VaccineProps {
  question: AdditionalQuestion;
  getAnswer: (id: string) => string;
  isOdd: boolean;
}

export function FormCheckVaccineItem({
  question,
  getAnswer,
  isOdd,
}: VaccineProps): JSX.Element {
  const answerValue = getAnswer(question.id) ?? '';
  return (
    <Box backgroundColor={isOdd ? 'dpcgray.100' : 'white'} py={4} px={2}>
      <Box>
        <FormControl
          display={{ base: 'block', md: 'flex' }}
          justifyContent="space-between"
          as="fieldset"
          isRequired
        >
          <Box>
            <FormLabel mb={0} as="legend" id={question.id}>
              {question.question}
            </FormLabel>
            <FormHelperText my={0}>{question.helper}</FormHelperText>
          </Box>
          <Box fontWeight="bold">{answerValue === '0' ? 'Nem' : 'Igen'}</Box>
        </FormControl>
      </Box>
      {question.additional && answerValue === '1' && (
        <Box mt={4}>
          <FormControl
            display={{ base: 'block', md: 'flex' }}
            justifyContent="space-between"
            as="fieldset"
            isRequired
          >
            <FormLabel mb={0} as="legend" id={question.additional.id}>
              {question.additional.question}
            </FormLabel>
            <Box fontWeight="bold">
              {getAnswer(question.additional.id) || ''}
            </Box>
          </FormControl>
        </Box>
      )}
    </Box>
  );
}
