import { RouteComponentProps } from '@reach/router';
import { graphql } from 'gatsby';
import React from 'react';

import FormPage from '../components/formpage/FormPage';
import { Layout } from '../components/layout/Layout';
import { VaccineTextMarkdownRemark, VaccineTexts } from '../interfaces';

interface Props {
  data: VaccineTextMarkdownRemark;
}

export default function HNYPage({
  data,
}: RouteComponentProps & Props): JSX.Element {
  return (
    <Layout>
      <FormPage
        vaccinetexts={data.allMarkdownRemark.edges.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.node.frontmatter.type]: {
              id: curr.node.id,
              html: curr.node.html,
              type: curr.node.frontmatter.type,
            },
          };
        }, {} as VaccineTexts)}
      />
    </Layout>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/vaccinetexts/" } }
    ) {
      edges {
        node {
          id
          frontmatter {
            type
          }
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
