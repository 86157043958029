import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import React from 'react';

import { VaccineTypes } from '../../../config';
import { VaccineQuestion } from '../../interfaces';

interface Props {
  vaccineQuestion: VaccineQuestion;
  getAnswer: (id: string) => string;
  setAnswer: (id: string, text: string) => void;
}

export default function VaccineItem({
  vaccineQuestion,
  getAnswer,
  setAnswer,
}: Props): JSX.Element {
  const renderQuestion = (): JSX.Element => (
    <Select
      value={getAnswer(vaccineQuestion.id) ?? 'X'}
      onChange={(e: React.FormEvent): void => {
        if (setAnswer)
          setAnswer(vaccineQuestion.id, (e.target as HTMLInputElement).value);
      }}
      my="auto"
    >
      {Object.entries(VaccineTypes).map(([key, value]) => (
        <option key={key} value={key}>
          {value.id}
        </option>
      ))}
    </Select>
  );

  return (
    <FormControl
      display={{ base: 'block', md: 'flex' }}
      justifyContent="space-between"
      my={4}
      id={vaccineQuestion.id}
    >
      <Box mr="1rem" mb={['1rem', null, 0]}>
        <FormLabel mb={0}>{vaccineQuestion.question}</FormLabel>
        <FormHelperText my={0}>{vaccineQuestion.helper}</FormHelperText>
      </Box>
      {renderQuestion()}
    </FormControl>
  );
}
