import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import { AdditionalQuestion, ErrorState } from '../../interfaces';

interface Props {
  question: AdditionalQuestion;
  getAnswer: (id: string) => string;
  setAnswer: (id: string, text: string) => void;
  errors: ErrorState;
  isOdd: boolean;
}

export default function QuestionItem({
  question,
  getAnswer,
  setAnswer,
  errors,
  isOdd,
}: Props): JSX.Element {
  const answerValue = getAnswer(question.id) ?? '';
  return (
    <Box backgroundColor={!isOdd ? 'dpcgray.100' : 'white'} py={4} px={2}>
      <Box>
        <FormControl
          display={{ base: 'block', md: 'flex' }}
          justifyContent="space-between"
          as="fieldset"
        >
          <Box>
            <FormLabel mb={0} as="legend" id={question.id}>
              {question.question}
            </FormLabel>
            <FormHelperText my={0}>{question.helper}</FormHelperText>
          </Box>
          <RadioGroup
            mt={2}
            name={question.id}
            value={answerValue}
            onChange={(value): void => {
              if (setAnswer) setAnswer(question.id, value);
            }}
          >
            <Flex justifyContent="flex-end">
              <Radio colorScheme="dpcblue" value="1" mr={4}>
                <Box
                  color={answerValue === '1' ? 'dpcblue.500' : 'inherit'}
                  fontWeight={answerValue === '1' ? 'bold' : 'normal'}
                >
                  Igen
                </Box>
              </Radio>
              <Radio colorScheme="dpcblue" value="0">
                <Box
                  color={answerValue === '0' ? 'dpcblue.500' : 'inherit'}
                  fontWeight={answerValue === '0' ? 'bold' : 'normal'}
                >
                  Nem
                </Box>
              </Radio>
            </Flex>
          </RadioGroup>
        </FormControl>
        {errors[question.id]?.length > 0 && (
          <Flex color="red.500" justify="flex-end">
            {errors[question.id].map((e) => (
              <Text key={e}>{e}</Text>
            ))}
          </Flex>
        )}
      </Box>
      {question.additional && answerValue === '1' && (
        <Box mt={4}>
          <FormControl
            display={{ base: 'block', md: 'flex' }}
            justifyContent="space-between"
            as="fieldset"
            isRequired
          >
            <FormLabel mb={0} as="legend" id={question.additional.id}>
              {question.additional.question}
            </FormLabel>
            <Input
              name={question.additional.id}
              value={getAnswer(question.additional.id) || ''}
              maxLength={question.additional.maxLength}
              onChange={(e: React.FormEvent): void => {
                if (setAnswer)
                  setAnswer(
                    question.additional.id,
                    (e.target as HTMLInputElement).value,
                  );
              }}
            />
          </FormControl>
          {errors[question.additional.id]?.length > 0 && (
            <Box color="red.500">
              {errors[question.additional.id].map((e) => (
                <Text key={e}>{e}</Text>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
